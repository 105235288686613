import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { MemberGroupService } from '../../services/member-group/member-group.service'
import { MessageService } from '../../services/message/message.service'

@Component({
  selector: 'app-member-group',
  templateUrl: './member-group.page.html',
  styleUrls: ['./member-group.page.scss'],
})
export class MemberGroupPage implements OnInit {
  memberGroups: any = []
  senders: any = []

  constructor(
    public modalController: ModalController,
    public loadingController: LoadingController,
    private memberGroupService: MemberGroupService,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    await this.getData()
  }

  async getData() {
    const loading = await this.loadingController.create({
      message: 'Loading...',
      spinner: 'dots'
    });
    await loading.present();
    await this.getMemberGroup()
    await this.getSender()
    await loading.dismiss();
  }

  async getMemberGroup() {
    const res: any = await this.memberGroupService.get();
    this.memberGroups = res.data
  }

  async getSender() {
    const res: any = await this.messageService.getSender();
    this.senders = res.data
  }

  selectGroup(groupId) {
    this.modalController.dismiss({ group_id: groupId });
  }

  selectSender(senderId) {
    this.modalController.dismiss({ sender_id: senderId });
  }

  closePage() {
    this.modalController.dismiss(null);
  }
}
