import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service'

@Injectable({
  providedIn: 'root'
})
export class MemberGroupService {

  constructor(
    private apiService: ApiService
  ) { }

  async get() {
    await this.apiService.setToken();
    return await this.apiService.get('member/member_group');
  }
}
