import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController, NavController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { MemberService } from '../../services/member/member.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  form: FormGroup;
  memberInfo: any = {
    user_key: '',
    email: '',
    name: '',
    password: ''
  };
  deviceType = null

  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public navController: NavController,
    private storage: Storage,
    private memberService: MemberService
  ) {
    this.deviceType = localStorage.getItem('device_type')
    this.createForm();
  }

  async ngOnInit() {
    const loading = await this.loadingController.create({
      message: 'Loading...',
      spinner: 'dots'
    });
    await loading.present();
    const res: any = await this.memberService.getProfile();
    this.memberInfo = res.data
    this.createForm();
    await loading.dismiss();
  }

  createForm() {
    this.form = this.formBuilder.group({
      user_key: [this.memberInfo.user_key],
      name: [this.memberInfo.name, Validators.required],
      phone_number: [this.memberInfo.phone_number],
      email: [this.memberInfo.email],
      password: [''],
    });
  }

  async update() {
    const loading = await this.loadingController.create({
      message: 'Loading...',
      spinner: 'dots'
    });

    await loading.present();
    const res: any = await this.memberService.updateProfile(this.form.value);
    await loading.dismiss();

    if (res.status == 'success') {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Message',
        message: 'Save data success.',
        buttons: ['Close']
      });
      await alert.present();
    } else {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Message',
        message: res.error,
        buttons: ['Close']
      });
      await alert.present();
    }
  }

  closePage() {
    this.modalController.dismiss(null);
  }

  openHome() {
    this.navController.navigateRoot(['/']);
  }

  openMessageSend() {
    this.navController.navigateRoot(['/message-send']);
  }

  logout() {
    if (this.deviceType != 'desktop') {
      this.modalController.dismiss(null);
    }

    this.storage.clear();
    this.navController.navigateRoot(['/login']);
  }
}
