import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { MessageService } from '../../services/message/message.service'

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.page.html',
  styleUrls: ['./message-detail.page.scss'],
})
export class MessageDetailPage implements OnInit {
  @Input() data: any;
  msg: any;

  constructor(
    public modalController: ModalController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private messageService: MessageService,
  ) { }

  async ngOnInit() {
    await this.getData()
  }

  async getData() {
    const loading = await this.loadingController.create({
      message: 'Loading...',
      spinner: 'dots'
    });

    await loading.present();
    const res: any = await this.messageService.find(this.data.id);
    this.msg = res.data
    await loading.dismiss();
  }

  async deleteMessage() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'Are you sure to delete this message?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Confirm',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Loading...',
              spinner: 'dots'
            });

            await loading.present();
            await this.messageService.delete(this.data.id);
            await loading.dismiss();
            this.closePage(true)
          }
        }
      ]
    });

    await alert.present();
  }

  closePage(reload = null) {
    this.modalController.dismiss(reload);
  }
}
